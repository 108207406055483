import axiosInstance from './BaseServiceInstance';

export async function getUserById() {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);

  try {    
    const response = await axiosInstance.get(`/v1/api/user/${data.id}`);
    
    if (response?.data?.qpsId && response?.data?.roles[0].name) {      
      const updatedData = { ...data, qpsId: response.data.qpsId, role: response.data.roles[0].name};
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(updatedData));
    }
    
    return response;
  } catch (error) {
    return true;
  }
}

export async function getUserByCuil(cuilNumber) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);  
  try {    
    const response = await axiosInstance.get(`/v1/api/user/cuil/${cuilNumber}`, {},
      {
        headers: {        
        Authorization: `Bearer ${data.token}`,
      },
    }
    );
    return response;
  } catch (error) {
    return true;
  }
}

// export async function getProfileData() {
//   const sessionData = sessionStorage.getItem("CURRENT_USER");
//   const data = JSON.parse(sessionData);

//   try {    
//     const response = await axiosInstance.get(`/v1/api/user/${data.id}`);
    
//     if (response?.data?.qpsId && response?.data?.roles[0].name) {      
//       const updatedData = { ...data, qpsId: response.data.qpsId, role: response.data.roles[0].name};
//       sessionStorage.setItem("CURRENT_USER", JSON.stringify(updatedData));
//     }
    
//     return response;
//   } catch (error) {
//     return true;
//   }
// }

export function getUserRole() {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  if (sessionData) {
    const user = JSON.parse(sessionData);
    return user.role;
  }
  return null;
}

export function getCompanyId() {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  if (sessionData) {
    const user = JSON.parse(sessionData);
    return user.companyId;
  }
  return null;
}