import React from "react";
import "./SideNav.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PieChartIcon from "@mui/icons-material/PieChart";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import testCompanyLogo from "../../assets/test-company.svg";
import fullLogo from "../../assets/full-logo.svg";
import { HeaderProfileMenu } from "../HeaderProfileMenu/HeaderProfileMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

export const SideNav = ({ pageTitle, isAdmin }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const isBetween1400And600 = useMediaQuery(
    "(max-width:1400px) and (min-width:600px)"
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const drawerWidth = isMobile ? "60%" : isBetween1400And600 ? "10%" : "20%";

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const getListItemStyle = (path) => {
    return location.pathname === path
      ? {
          borderRadius: "16px",
          padding: "12px",
          background:
            "linear-gradient(255.05deg, #002E61 13.81%, #214584 59.85%, #85D1EF 113.2%)",
          backgroundBlendMode: "multiply",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          color: "#FFFFFF",
          "& .MuiListItemIcon-root": {
            color: "#FFFFFF",
          },
        }
      : {
          color: "#042E5D",
          "& .MuiListItemIcon-root": {
            color: "#042E5D",
          },
        };
  };

  const links = [
    {
      path: "/dashboard",
      icon: <PieChartIcon />,
      label: t("Navegacion.Vision General"),
      visibleFor: "admin",
    },
    {
      path: "/portfolio",
      icon: <WorkOutlineOutlinedIcon />,
      label: t("Navegacion.Portfolio segun Perfil"),
      visibleFor: "admin",
    },
    {
      path: "/usuarios",
      icon: <FolderOutlinedIcon />,
      label: t("Navegacion.Planes de Usuarios"),
      visibleFor: "all",
    },
    {
      path: "/benchmark",
      icon: <ImportContactsOutlinedIcon />,
      label: "BenchMark",
      visibleFor: "all",
    },
    {
      path: "/gestion-empresas",
      icon: <AccountBoxOutlinedIcon />,
      label: t("Navegacion.Gestion de Empresas"),
      visibleFor: "admin",
    },
    {
      path: "/configuracion",
      icon: <SettingsOutlinedIcon />,
      label: t("Navegacion.Configuracion & Soporte"),
      visibleFor: "all",
    },
  ];

  
  const linksToDisplay = links.filter(
    (link) => link.visibleFor === "all" || (link.visibleFor === "admin" && isAdmin)
  );

  const drawer = (
    <div
      className="drawer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Toolbar />
        <img
          src={testCompanyLogo}
          alt="Company Logo"
          style={{
            width: "100%",
            height: "auto",
            padding: "10px",
            maxWidth: "254px",
          }}
        />
        <Divider />
        <List>
          {linksToDisplay.map((link) => (
            <ListItem key={link.path} onClick={() => navigate(link.path)}>
              {isBetween1400And600 ? (
                <Tooltip title={link.label} placement="right">
                  <ListItemButton sx={getListItemStyle(link.path)}>
                    <ListItemIcon>
                      <div className="nav-icon-container">{link.icon}</div>
                    </ListItemIcon>
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                </Tooltip>
              ) : (
                <ListItemButton sx={getListItemStyle(link.path)}>
                  <ListItemIcon>
                    <div className="nav-icon-container">{link.icon}</div>
                  </ListItemIcon>
                  <ListItemText primary={link.label} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>

      </div>
      <div
        className="drawer-bottom-logo"
        style={{
          padding: "10px",
          textAlign: "center",
          marginTop: "auto",
        }}
      >
        <img
          src={fullLogo}
          alt="Bottom Logo"
          style={{
            width: "80%",
            height: "auto",
            maxWidth: "200px",
          }}
        />
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        sx={{
          height: "60px",
          width: "60px",
          left: 0,
          padding: "20px",
          backgroundColor: "white",
          display: "flex",
        }}
      >
        <div className="u-displayFlex mobile-header">
          <IconButton
            color="#343C6A"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 0, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="page-title u-displayFlex">
            <h2>{pageTitle}</h2>
          </div>
          <HeaderProfileMenu />
        </div>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
