import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Cell } from 'recharts';
import formatMonthYear from '../../helpers/stringKeyFormatter';

const HorizontalGradientBarChart = ({ participantEvolutionDTO }) => {
  if (!participantEvolutionDTO || participantEvolutionDTO.length === 0) {
    return <div style={{ textAlign: 'center', padding: '20px' }}>No Hay datos para mostrar</div>;
  }
  
  const parseDate = (period) => {
    const [month, year] = period.split('/');
    return new Date(`${year}-${month}-01`);
  };

  const sortedData = participantEvolutionDTO.sort(
    (a, b) => parseDate(a.period) - parseDate(b.period)
  );

  const formattedData = sortedData.map((entry) => ({
    ...entry,
    formattedPeriod: formatMonthYear(entry.period),
  }));

  const CustomTick = ({ x, y, payload }) => {
    const [month, year] = payload.value.split("\n");
    return (
      <text x={x} y={y} dy={4} textAnchor="end" fill="#666" style={{ fontSize: 12 }}>
        <tspan x={x} dy="-1em">{month}</tspan>
        <tspan x={x} dy="1em">{year}</tspan>
      </text>
    );
  };

  return (
    <div style={{ width: '100%', height: 240, marginTop: 15 }}>
      <ResponsiveContainer>
        <BarChart
          data={formattedData}
          layout="vertical"
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
          barCategoryGap={5}
          barGap={1}
        >
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#85D1EF" stopOpacity={1} />
              <stop offset="40%" stopColor="#214584" stopOpacity={1} />
              <stop offset="100%" stopColor="#002E61" stopOpacity={1} />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            type="category"
            dataKey="formattedPeriod"
            axisLine={false}
            tickLine={false}
            tick={<CustomTick />}
          />

          <Tooltip
            formatter={(value, name) => [`${value}`, 'Participantes: ']}
            labelFormatter={(label) => `Participantes: ${label}`}
          />

          <Bar
            dataKey="evolutionPercentage"
            fill="url(#colorGradient)"
            barSize={20}
          >
            {formattedData.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizontalGradientBarChart;
